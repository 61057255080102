import * as React from 'react';

import classnames from 'classnames';

type Props = {
    children: React.ReactNode;
    tag?: 'div' | 'section';
    align?: 'center' | 'start';
};

const CtaBox: React.FunctionComponent<Props> = ({ children, tag = 'div', align = 'center' }) => {
    const className = classnames({
        'cta-box': true,
        [`cta-box--align-${align}`]: align,
    });

    const Tag = tag as React.ElementType;

    return <Tag className={className}>{children}</Tag>;
};

export default CtaBox;
