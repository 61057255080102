import React, { ReactNode, FunctionComponent } from 'react';

import classnames from 'classnames';

type Props = {
    children: ReactNode;
    width?: 'stretch' | 'content';
    neutral?: boolean;
};

const CtaBoxContent: FunctionComponent<Props> = ({ children, width = 'content', neutral }) => {
    const className = classnames({
        'cta-box__content': true,
        [`cta-box__content--width-${width}`]: width,
        'cta-box__content--neutral': neutral,
    });

    return <div className={className}>{children}</div>;
};

export default CtaBoxContent;
