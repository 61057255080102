import { CdnImagePath } from 'lib/cdn-image-path';
import configJson from 'lib/images-list.json';
const config: Config = configJson as Config;

export type ImageConfig = {
    src: string;
    blurhash?: string;
    base64?: string;
};

type Config = {
    [key: string]: ImageConfig;
};

export function getImageData(path?: string) {
    const correctedPath = `public/${path?.replace(`${CdnImagePath}/`, '')}`;
    const imageData = config[correctedPath];

    if (imageData) {
        return {
            src: `${CdnImagePath}${imageData.src}`,
            blurDataUrl: imageData.base64,
        };
    }

    return {
        src: `${CdnImagePath}${path}`,
    };
}
