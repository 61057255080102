const CDNInternalList = {
    development: '/bumble-brand-assets',
    test: '/bumble-brand-assets',
    production: '/bumble-brand-assets',
    // test: 'https://bumbcdn.com/i/big/documents/bumble/bumble-rebrand/2024-05-08/assets/bumble',
    // production:
    // 'https://bumbcdn.com/i/big/documents/bumble/bumble-rebrand/2024-05-08/assets/bumble',
};

const setCDN = () => {
    // if (process.env.CDN_IMAGE_DOMAIN_URL) {
    //     return process.env.CDN_IMAGE_DOMAIN_URL;
    // }

    return CDNInternalList[process.env.NODE_ENV];
};

export const CdnImagePath = setCDN();
