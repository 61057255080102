import React, { ReactNode, Children, FunctionComponent } from 'react';

import cx from 'classnames';

type Props = {
    children?: ReactNode | ReactNode[];
    /*
        Spacing between items in column layout. Loose - for cards without fil, compact - for cards with fill
    */
    spacing?: 'compact' | 'loose';
    a11y?: {
        label: string;
    };
};

const Cards: FunctionComponent<Props> = ({ children, a11y, spacing = 'loose' }) => {
    const childrenArray = Children.toArray(children).filter(Boolean);

    const className = cx({
        cards: true,
        [`cards--spacing-${spacing}`]: spacing,
    });

    return (
        <ul className={className} role="list" aria-label={a11y?.label}>
            {childrenArray.map((child, index) => (
                <li className="cards__item" key={index}>
                    {child}
                </li>
            ))}
        </ul>
    );
};

export default Cards;
