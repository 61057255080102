import React, { ReactNode, FunctionComponent } from 'react';

import classnames from 'classnames';

type CtaBoxTextProps = {
    children?: ReactNode | string;
    color?: 'default' | 'inverse' | 'brand';
};

const CtaBoxText: FunctionComponent<CtaBoxTextProps> = ({ children, color = 'default' }) => {
    const className = classnames({
        'cta-box__text': true,
        [`color-${color}`]: color,
    });

    return (
        <div className={className} data-qa="cta-box-text">
            {children}
        </div>
    );
};

export default CtaBoxText;
