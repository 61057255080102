import React, { ReactNode, FunctionComponent, ElementType } from 'react';

import classnames from 'classnames';

type Props = {
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
    color?: 'default' | 'inverse' | 'brand';
    children?: ReactNode | string;
};

const CtaBoxHeader: FunctionComponent<Props> = ({ tag = 'div', children, color = 'default' }) => {
    const Tag = tag as ElementType;

    const className = classnames({
        'cta-box__header': true,
        [`color-${color}`]: color,
    });

    return <Tag className={className}>{children}</Tag>;
};

export default CtaBoxHeader;
