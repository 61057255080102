import React, { ReactNode, FunctionComponent } from 'react';

import Stack, { StackProps } from 'components/stack/stack';

type Props = {
    children: ReactNode;
    align?: StackProps['align'];
};
const CtaBoxActions: FunctionComponent<Props> = ({ children, align = 'center' }) => {
    return (
        <div className="cta-box__actions">
            <Stack align={align}>{children}</Stack>
        </div>
    );
};

export default CtaBoxActions;
